import React, { useEffect, useState } from 'react'
import './giproduct.css'
import { GoChevronDown } from "react-icons/go";
import { FiSearch } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { IoIosClose } from "react-icons/io";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbCaretUpDownFilled } from "react-icons/tb";


const GiProducts = () => {
  const [products, setProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(()=>{
    fetchData();
  },[]);


  const fetchData = async () =>{
    try {
      // Replace 'YOUR_BACKEND_ENDPOINT' with the actual endpoint
      const response = await axios.get('/getallproducts');
      setProducts(response.data);
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedProducts(products.map(product => product._id));
    } else {
      setSelectedProducts([]);
    }
  };
  
  const handleSelectProduct = productId => {
    const updatedSelectedProducts = selectedProducts.includes(productId)
      ? selectedProducts.filter(id => id !== productId)
      : [...selectedProducts, productId];
    setSelectedProducts(updatedSelectedProducts);
  };
  
  const handleProductClick = product => {
    console.log(product);
  };

  const handleDeleteSelected = async () => {
    try {
      await axios.delete('/bulkdelete', { data: { productIds: selectedProducts } });
      // Remove the deleted products from the list
      setProducts(products.filter(product => !selectedProducts.includes(product._id)));
      // Clear the selected products array
      setSelectedProducts([]);
    } catch (error) {
      console.error('Error deleting products:', error);
    }
  };

  const handleDelete = async (productId) => {
    try {
      await axios.delete(`/deleteproduct/${productId}`);
      // Remove the deleted product from the list
      setProducts(products.filter(product => product._id !== productId));
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };
  console.log(products)
  


  return (
    <div className='product-container'>
      <div className='head_bar'>
        <div className='head-search'>
          <div className='filter'>
                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.79 1.61564C12.3029 0.959102 11.8351 0 11.002 0H1.00186C0.168707 0 -0.299092 0.959101 0.213831 1.61564L5.03983 7.72867C5.1772 7.90449 5.25181 8.1212 5.25181 8.34432V13.7961C5.25181 13.9743 5.46724 14.0635 5.59323 13.9375L6.60536 12.9254C6.69913 12.8316 6.75181 12.7044 6.75181 12.5718V8.34432C6.75181 8.1212 6.82643 7.90449 6.96379 7.72867L11.79 1.61564Z" fill="#5A5B6A"/>
                </svg>
                All
                <GoChevronDown/>

          </div>
          <div className='filter-search'>
              <FiSearch/>
              <input type='text' placeholder='Search'/>
          </div>


        </div>
        <div className='head-right'>
            <button className='red-btn'><IoIosClose size={20} onClick={handleDeleteSelected}/>Delete</button>
            <Link to='/dashboard/addproduct'>
            <button className='blue-btn'><GoPlus size={20}/>Add Gi Product</button>
            </Link>
        </div>
      </div>

      <div className='product_body'>
        <div className='main-container'>
        <table>
    <thead>
      <tr>
        <th>
        <input type='checkbox' className="custom-checkbox" onChange={handleSelectAll} checked={selectAll} />
        <span className="checkmark"></span>
        </th>
        <th><span className='title'># <TbCaretUpDownFilled /></span></th>
        <th><span className='title'>PRODUCT NAME <TbCaretUpDownFilled /></span></th>
        <th><span className='title'>CATEGORY <TbCaretUpDownFilled /></span></th>
        <th><span className='title'>TYPE <TbCaretUpDownFilled /></span></th>
        <th><span className='title'>PRICE <TbCaretUpDownFilled /></span></th>
        <th><span className='title'>INVENTORY <TbCaretUpDownFilled /></span></th>
        <th><span className='title'>VOLUME <TbCaretUpDownFilled /></span></th>
        <th><span className='title'>VARIATION <TbCaretUpDownFilled /></span></th>
        <th>ACTION</th>
        
      </tr>
    </thead>
    <tbody>
  {/* Display fetched products */}
  {products.map((product, index) => (
    <tr key={product._id}>
      <td>
          <input
              type='checkbox'
              className="custom-checkbox"
              onChange={() => handleSelectProduct(product._id)}
              checked={selectedProducts.includes(product._id)}
            />
            <span className="checkmark"></span>
      </td>
      <td>{index + 1}</td>
      <td onClick={() => handleProductClick(product)}><Link to={`/dashboard/viewproduct/${product._id}`}>{product.name}</Link> </td>
      <td>{product.category}</td>
      <td>{product.type}</td>
      <td>{product.price}/-</td>
      <td><span className='numeric'>{product.inventory}</span></td>
      <td><span className='numeric'>{product.volume}</span></td>
      <td>{product.variation}</td>
      <td className='actions'><Link to={`/dashboard/editproduct/${product._id}`}>
                              <MdEdit color='green' size={17} />
                              </Link>
                              <RiDeleteBin6Line color='red' size={17} onClick={() => handleDelete(product._id)}/>
      </td>
    </tr>
  ))}
</tbody>
  </table>

          

          </div>
        </div>



     </div>     
  )
}

export default GiProducts