import React, { useState } from 'react';
import './payment.css';

function Payment({ nextStep }) {
  const [selectedCard, setSelectedCard] = useState('');
  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [cvv, setCvv] = useState('');
  const [saveCard, setSaveCard] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const savedCardDetails = [
    "Visa card ending with 1234",
    "MasterCard ending with 5678",
    "RuPay card ending with 7856"
  ];

  const handleCardChange = (event) => {
    setSelectedCard(event.target.value);
  };

  const handleCardNameChange = (event) => {
    setCardName(event.target.value);
  };

  const handleCardNumberChange = (event) => {
    setCardNumber(event.target.value);
  };

  const handleExpiryMonthChange = (event) => {
    setExpiryMonth(event.target.value);
  };

  const handleExpiryYearChange = (event) => {
    setExpiryYear(event.target.value);
  };

  const handleCvvChange = (event) => {
    setCvv(event.target.value);
  };

  const handleSaveCardChange = (event) => {
    setSaveCard(event.target.checked);
  };

  

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    if (validateForm()) {
      // Proceed to next step if form is valid
      nextStep();
    } else {
      console.log("Form is not valid. Please fill in all fields correctly.");
    }
  };

  const validateForm = () => {
    return (
     
      cardName.trim() !== '' &&
      cardNumber.trim() !== '' &&
      expiryMonth.trim() !== '' &&
      expiryYear.trim() !== '' &&
      cvv.trim() !== '' &&
      cardNumber.match(/^\d+$/) // Ensure card number contains only digits
    );
  };

  return (
    <div className='delivery_container'>
      <h1>Enter Payment Details</h1>
      <form onSubmit={handleSubmit}>
        <div className='split_section'>
          <p>Use saved cards</p>
          <span>
            <select value={selectedCard} onChange={handleCardChange} className="custom-select">
              <option value="">Select a card</option>
              {/* Map through pre-saved cards and create options */}
              {savedCardDetails.map((card, index) => (
                <option key={index} value={card}>{card}</option>
              ))}
            </select>
          </span>
        </div>
        <label htmlFor='card_name'>Name on card</label>
        <input type='text' id='card_name' value={cardName} onChange={handleCardNameChange} className={(cardName.trim() === '' && formSubmitted) ? 'red-border' : ''} required />
        <label htmlFor='card_number'>Enter card number</label>
        <input type='text' id='card_number' placeholder='XXXX-XXXX-XXXX-XXXX' value={cardNumber} onChange={handleCardNumberChange} className={(cardNumber.trim() === '' && formSubmitted) ? 'red-border' : ''} pattern="\d{16}" title="Please enter a 16-digit card number" required />

        <div className='date_cvv'>
          <div className='date'>
            <label htmlFor='mm yy'>Valid upto</label>
            <span className='expiry'>
              <input type='text' id='mm' placeholder='MM' value={expiryMonth} onChange={handleExpiryMonthChange} className={(expiryMonth.trim() === '' && formSubmitted) ? 'red-border' : ''} pattern="\d{2}" title="Please enter a valid two-digit month" required />
              <span className='slash'>/</span>
              <input type='text' id='yy' placeholder='YY' value={expiryYear} onChange={handleExpiryYearChange} className={(expiryYear.trim() === '' && formSubmitted) ? 'red-border' : ''} pattern="\d{2}" title="Please enter a valid two-digit year" required />
            </span>
          </div>
          <span className='cvv'>
            <label htmlFor='card_cvv'>Enter CVV</label>
            <input type='text' id='card_cvv' value={cvv} onChange={handleCvvChange} className={(cvv.trim() === '' && formSubmitted) ? 'red-border' : ''} pattern="\d{3}" title="Please enter a valid three-digit CVV" required />
          </span>
        </div>

        <div className="save_address_checkbox">
          <input type="checkbox" id="save_card" checked={saveCard} onChange={handleSaveCardChange} />
          <label htmlFor="save_card" className='check_save'><span>Save this card for future</span></label>
        </div>

        <div className='remaining-fields'>
          {!validateForm() && formSubmitted && <span className="red-text">Please fill in all fields.</span>}
        </div>

        <div className='btn'>
          <button type="submit" >Make Payment</button>
        </div>
      </form>
    </div>
  );
}

export default Payment;
