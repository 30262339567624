import React, { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, ZoomControl } from "react-leaflet";
import "./Map.css";
import L from "leaflet";
import ProductPopup from "../ProductDetail/ProductPopup";
import plants from './icons/plants.svg';
import textile from './icons/textile.svg'
import food from './icons/food.svg'
import pharma from './icons/pharma.svg'
// import product from './product.js';
import axios from "axios";




const MapView = (props) => {
  const { selectedCategory } = props;
  const [products, setProducts] = useState([]);


  useEffect(() => {
    fetchProduct();
  }, []);

  const fetchProduct = async () =>{
    try {
      const response = await axios.get('/getallproducts');
      console.log('Response data:', response.data);
      const productsWithCoordinates = response.data.map(product => {
        const { coordinates } = product.location;
        const latitude = coordinates[0]; 
        const longitude = coordinates[1];
        return {
          ...product,
          latitude,
          longitude
        };
      });
      setProducts(productsWithCoordinates);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const getCustomIcon = (category) => {
    switch(category) {
      case 'Agriculture':
        return new L.Icon({ iconUrl: plants, iconSize: [64, 64] });
      case 'Textile':
        return new L.Icon({ iconUrl: textile, iconSize: [64, 64] });
      case 'Food':
        return new L.Icon({ iconUrl: food, iconSize: [64, 64] });
      case 'Pharma':
        return new L.Icon({ iconUrl: pharma, iconSize: [64, 64] });
      default:
        return null; 
    }
  };

  const filteredProducts = selectedCategory ? products.filter(product => product.category === selectedCategory) : products;

  
  return (
    <MapContainer
      style={{ height: "100vh" }}
      id="map"
      center={[12.002, 77.5907]}
      zoom={13}
      
      zoomControl={false}
    >
      
      <TileLayer
        url="https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZGVjb3JzbmFwIiwiYSI6ImNrcTVjNmRwdzByM20ybnBwenVpbTBwcG8ifQ.-CUcCijKzxvvfry7qqxdzQ"
        attribution='© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      />
      

      {filteredProducts.map((product) => (
          <Marker key={product._id} position={[product.latitude, product.longitude]} icon={getCustomIcon(product.category)}>
            <Popup>
              <ProductPopup
                product={product}
                handleProductView={() => {}}
                handleProductDetail={() => console.log("View product details")}
              />
            </Popup>
          </Marker>
        ))}
      <ZoomControl position={"bottomleft"} />
    </MapContainer>
  );
};

export default MapView;
