import React, { useState, useEffect } from 'react';
import './ProductPopup.css';
import { MdFastfood } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { MdAddShoppingCart } from 'react-icons/md';
import axios from 'axios';
import { useAuth } from '../../context/userContext';
import { BsCartCheck } from "react-icons/bs";

function ProductPopup({ product, handleProductView, handleProductDetail }) {
  const { user } = useAuth();
  const [isAddedToCart, setIsAddedToCart] = useState(false);

  useEffect(() => {
    const checkCart = async () => {
      if (user && user.user && user.user._id) {
        try {
          const response = await axios.get(`/api/cart/get`);

          if (response.status === 200) {
            const cartItems = response.data.items;
            const isAlreadyAdded = cartItems.some(
              (item) => item.product === product._id
            );
            setIsAddedToCart(isAlreadyAdded);
          }
        } catch (error) {
          console.error('Error checking cart:', error);
        }
      }
    };

    checkCart();
  }, [user, product._id]);

  const handleAddToCart = async () => {
    try {
      if (!user || !user.user || !user.user._id) {
        console.error('Invalid user data:', user);
        return;
      }

      const { _id: userId, name, email } = user.user;

      const response = await axios.post('/api/cart/add', {
        productId: product._id,
        quantity: 1,
        user: { _id: userId, name, email },
      });

      if (response.status === 201) {
        setIsAddedToCart(true);
      } else {
        throw new Error('Failed to add item to cart');
      }
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };

  if (!product || !product.images || product.images.length === 0 || !product.images[0]) {
    return null;
  }

  const firstImageUrl = `https://back.tryba.online/uploads/${product.images[0][0]}`;

  return (
    <div className='pop_container'>
      <div className='product_image'>
        <img src={firstImageUrl} alt='Product' />
      </div>
      <div className='product_details'>
        <h2>{product.name}</h2>
        <div className="category_price">
          <p className='price'>Price @ {product.price}/-</p>
          <div className='category'>
            <MdFastfood/>
            <p>{product.category}</p>
          </div>
        </div>
        <div className='detail_button'>
          <Link to={`/productdetail/${product._id}`}>
            <button className='info-btn' onClick={handleProductDetail}>More info</button>
          </Link>
          {isAddedToCart ? (
            <button className='to-cart-btn' disabled>
              <BsCartCheck size={16}/>
              Added To Cart
            </button>
          ) : (
            <button className='to-cart-btn' onClick={handleAddToCart}>
              <MdAddShoppingCart size={16}/>
              Add To Cart
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductPopup;
