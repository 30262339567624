const options = [
    {
        id: 1,
        name: 'Higher Price',
        
      },
    {
        id: 2,
        name: 'Lower Price',
       
      },
    {
        id: 3,
        name: 'Select GI Product',
        subItems: [
          { id: 31, name: 'Agriculture' },
          { id: 32, name: 'Textiles & Handloom' },
          { id: 33, name: 'Food Products' },
          { id: 34, name: 'Medicine' },
        ],
      },
      {
        id: 4,
        name: 'Select Product Type',
        subItems: [
          { id: 41, name: 'Type-1' },
          { id: 42, name: 'Type-2' },
          { id: 43, name: 'Type-3' },
          { id: 44, name: 'Type-4' },
        ],
      },
      {
        id: 5,
        name: 'Select Materials',
        subItems: [
          { id: 51, name: 'Material-1' },
          { id: 52, name: 'Material-2' },
          { id: 53, name: 'Material-3' },
          { id: 54, name: 'Material-4' },
        ],
      },
      {
        id: 6,
        name: 'Select Ratings',
        subItems: [
          { id: 61, name: '⭐ or more' },
          { id: 62, name: '⭐⭐ or more' },
          { id: 63, name: '⭐⭐⭐ or more' },
          { id: 64, name: '⭐⭐⭐⭐ or more' },
        ],
      },
      {
        id: 7,
        name: 'Select Stock Status',
        subItems: [
          { id: 71, name: 'Available' },
          { id: 72, name: 'Selling Quickly' },
          { id: 73, name: 'Sold Out' },
          
        ],
      },
];

export default options