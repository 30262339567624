import React from "react";
import "./Buyer.css";
import account from "../icons/account.png";
import { RiDashboardFill } from "react-icons/ri";
import { FaTruck } from "react-icons/fa";
import { LuLogIn } from "react-icons/lu";
const Buyer = () => {
  return (
    <div className="buyer-section">
      <div className="buyer-div">
        <img
          src={account}
          alt="account"
          width={"25px"}
          height={"25px"}
          className="img-account"
        />

        <p>Account Settings</p>
      </div>

      <div className="buyer-div">
        <RiDashboardFill size={20} />
        <p>Dashboard</p>
      </div>

      <div className="buyer-div">
        <FaTruck size={20} />
        <p>Oredr Tracking & History</p>
      </div>

      <div className="buyer-div">
        <LuLogIn size={20} />
        <p>Logout</p>
      </div>
    </div>
  );
};

export default Buyer;
