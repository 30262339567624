import React from 'react'
import { IoShareSocialOutline } from 'react-icons/io5';
import { MdFavoriteBorder } from 'react-icons/md';
import './cartItems.css'
import shopping from './undraw_empty_cart_co35.svg';

function CartItems({cartEmpty, cartItems, removedItemId, decreaseQuantity, increaseQuantity, removeItem  }) {
  return (
    <div className='items_container'> 
        {cartEmpty ? (
        <div className='alternate_cart'>
          <div className='alternate_cart_container'>
            <img src={shopping} alt='shopping cart' />
            <h4><b>Your cart's feeling a bit light </b></h4>
            <button>Start adding items now!</button>
          </div>
        </div>
      ) : (
        <div className='list'>
          {/* Render products grouped by the name of owner */}
          {Object.entries(cartItems.reduce((acc, cur) => {
            acc[cur.ownerName] = [...(acc[cur.ownerName] || []), cur];
            return acc;
          }, {})).map(([ownerName, products]) => (
            <div key={ownerName} className='owner'>
              <div className='owner_info'>
                <img src={products[0].ownerPhoto} alt='Owner' className='owner_photo' />
                <h3>{ownerName}</h3>
                <p>{products[0].industry}</p>
              </div>

              {products.map(product => (
                <div key={product.id} className={`cart_item ${removedItemId === product.id ? 'remove_animation' : ''}`}>
                  <div className='about_product'>
                    <img src={product.image1} alt={product.name} className='product_image' />
                    <div className='product_details'>
                      <p className='product_name'>{product.name}</p>
                      <p className='product_price'>Rs.{product.price}</p>
                      <p className='product_variation'>{product.variation}</p>
                    </div>
                  </div>

                  <div className='actions'>
                    <IoShareSocialOutline size={20} color='#5A5B6A' />
                    <MdFavoriteBorder size={20} color='#5A5B6A' />
                    <div className='quantity'>
                      <span onClick={() => decreaseQuantity(product.id)}>-</span>
                      <p>{product.quantity}</p>
                      <span onClick={() => increaseQuantity(product.id)}>+</span>
                    </div>
                    <button className='remove_btn' onClick={() => removeItem(product.id)}>Remove</button>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}</div>
  )
}

export default CartItems