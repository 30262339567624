import React, { useState } from 'react';
import './CartComponent.css';
import { VscClose } from "react-icons/vsc";
import { MdLockOutline } from "react-icons/md";
import cartList from './cartList'
import CartItems from './CartItems';
import DeliveryInfo from './DeliveryInfo';
import Payment from './Payment';
import Confirmation from './Confirmation';
import CartSummary from './CartSummary';

function CartComponent({ toggleShowCart }) {
  const [cartItems, setCartItems] = useState(Object.values(cartList));
  const [removedItemId, setRemovedItemId] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  


  const increaseQuantity = (id) => {
    const updatedCartItems = cartItems.map(item => {
      if (item.id === id) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });
    setCartItems(updatedCartItems);
  };

  const decreaseQuantity = (id) => {
    const updatedCartItems = cartItems.map(item => {
      if (item.id === id && item.quantity > 0) {
        return { ...item, quantity: item.quantity - 1 };
      }
      return item;
    });
    setCartItems(updatedCartItems);
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    cartItems.forEach(item => {
      totalPrice += item.price * item.quantity;
    });
    return totalPrice;
  };

  const removeItem = (id) => {
    setRemovedItemId(id);
    setTimeout(() => {
      const updatedCartItems = cartItems.filter(item => item.id !== id);
      updatedCartItems.sort((a, b) => a.id - b.id);
      setCartItems(updatedCartItems);
    }, 600);
  };


  const clearCart = () => {
    setCartItems([]);
  };

  let buttonText = '';
  if (currentStep === 1) {
    buttonText = 'Clear All';
  } else if (currentStep === 2 || currentStep === 3) {
    buttonText = 'Go Back';
  }


  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const getStepLabel = (step) => {
    switch (step) {
      case 1:
        return "View Cart";
      case 2:
        return "Select Address";
      case 3:
        return "Make Payment";
      case 4:
        return "Order Confirmation";
      default:
        return "";
    }
  };

  const totalSteps = 4;
  const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
  const cartEmpty = totalItems === 0;

  return (
    <div className='cart_overlay'>
      <header>
        <div className='header_cont'>
          <h2>GFOKK</h2>
          <button className='close_btn' onClick={toggleShowCart}>
            <VscClose />
          </button>
        </div>
      </header>

      <div className='cart_body'>
        <div className='left'>
          <div className='cart_container'>
            <div className='head'>
              <h4>Shopping Cart</h4>
              {currentStep < 4 && <button onClick={currentStep === 1 ? clearCart : () => setCurrentStep(currentStep - 1)}>{buttonText}</button>}
            </div>
            <div className="progress-bar">
              {[...Array(totalSteps)].map((_, index) => (
                <div key={index} className={`progress-step ${currentStep === index + 1 ? 'active' : ''}`}>
                  <div className="step-dot"></div>
                  {index !== totalSteps - 1 && <div className="step-line"></div>}
                  {currentStep === index + 1 && <div className="step-label">{getStepLabel(index + 1)}</div>}
                </div>
              ))}
            </div>
            {/* cartEmpty,cartItems, removedItemId, decreaseQuantity, increaseQuantity, removeItem */}
           
          {currentStep === 1 && (
            <>
               
              <div className='item_list'>
               <CartItems cartEmpty={cartEmpty}
                      cartItems={cartItems}
                      removedItemId={removedItemId}
                      decreaseQuantity={decreaseQuantity}
                      increaseQuantity={increaseQuantity}
                      removeItem={removeItem} />
               
              </div>
              </>
            )}
            {currentStep === 2 && (
              <div className='item_list'>
                <DeliveryInfo nextStep={nextStep}/>
              </div>
            )}

            {currentStep === 3 && (
              <div className='item_list'>
                <Payment nextStep={nextStep}/>
              </div>
            )}

            {currentStep === 4 && (
              <div className='item_list'>
                <Confirmation/>
              </div>
            )}
          </div>
          
        </div>
        
          <div className='right'>
            <div className='summary'>
            <div className='head'>
              <h4>{currentStep === 1 ? 'Summary' : 'Order Review'}</h4>
              <p>{totalItems} items</p>
            </div>
              {cartEmpty ? (<p className='cart_empty'>Add product to get summary</p>)
                      :
                      (
                        <>
                        {(currentStep === 2 || currentStep === 3 || currentStep === 4) && (
                          <>
                        <div className='cart_summary'>
                          <CartSummary cartItems={cartItems}/>
                        </div>
                        <hr/>
                        </>
                        )}
                        
                          <div className='order_info'>
                            <div className='section'>
                              <p>Subtotal</p>
                              {/* Calculate subtotal dynamically */}
                              <p>Rs.{calculateTotalPrice()}</p>
                            </div>
                            <div className='section'>
                              <p>Shipping</p>
                              <p>Rs.49</p>
                            </div>
                            <div className='section'>
                              <p>Tax</p>
                              <p>Not Applicable</p>
                            </div>
                          </div>
                          <hr/>
                          <div className='total_price'>
                            <p><b>Total Price</b></p>
                            {/* Display total price dynamically */}
                            <span>Rs.{calculateTotalPrice() + 49}</span>
                          </div>

                          {currentStep === 1 && (
                              <>
                                <button className='checkout_btn' onClick={nextStep}>Checkout</button>
                                <button className='secured_btn'><MdLockOutline size={18} />Secured checkout</button>
                              </>
                            )}
                        </>
                      )
                    
                    }
              </div>
          </div>
       
      </div>
    </div>
  );
}

export default CartComponent;
