const cartList = {
  //   1: {
  //     id: 1,
  //     name: "Nagpur Oranges",
  //     price: 99,
  //     seller: "Fruit Store",
  //     description: "Description text lorem ipsum product content lorem ipsum product content will go here and various informationDescriptionDescription text lorem ipsum product content lorem ipsum product content will go here and various information",
  //     category: "Fruits",
  //     type: "Orange",
  //     variation: "Nagpur",
  //     origin: "Nagpur, India",
  //     materials: ["N/A"],
  //     image1: "https://images.unsplash.com/photo-1547514701-42782101795e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDN8fHxlbnwwfHx8fHw%3D",
  //     ownerName: "Ramesh Singh",
  //     ownerPhoto: "https://images.unsplash.com/photo-1600180758890-6b94519a8ba6?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //     industry: "Agriculture",
  //     quantity: 1

  //   },
  //   2: {
  //     id: 2,
  //     name: "Handmade Ceramic Mug",
  //     price: 25,
  //     seller: "Artisan Craftworks",
  //     description: "Beautiful handmade ceramic mug, perfect for your morning coffee or tea.",
  //     category: "Handmade",
  //     type: "Ceramic Mug",
  //     variation: "Various Colors",
  //     origin: "Artisan Workshop",
  //     materials: ["Ceramic"],
  //     image1: "https://i.pinimg.com/564x/e4/84/1a/e4841a1403174e333bf1060933278ca9.jpg",
  //     ownerName: "Ananya Patel",
  //     ownerPhoto: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D",
  //     industry: "Handmade",
  //     quantity: 1
  // },
  //   3: {
  //     id: 3,
  //     name: "Vintage Leather Jacket",
  //     price: 150,
  //     seller: "Retro Fashion Emporium",
  //     description: "Classic vintage leather jacket, perfect for adding a touch of retro style to your wardrobe.",
  //     category: "Fashion",
  //     type: "Leather Jacket",
  //     variation: "Vintage",
  //     origin: "Italy",
  //     materials: ["Leather"],
  //     image1: "https://i.pinimg.com/564x/20/67/1f/20671f52c89b2c74e2d28987fc19cb92.jpg",
  //     ownerName: "Michael Smith",
  //     ownerPhoto: "https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?q=80&w=1480&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //     industry: "Fashion",
  //     quantity: 1
  //  },
  //   4: {
  //     id: 4,
  //     name: "Organic Avocado",
  //     price: 3,
  //     seller: "Organic Market",
  //     description: "Fresh, organic avocados sourced directly from local farms.",
  //     category: "Produce",
  //     type: "Avocado",
  //     variation: "Organic Hass",
  //     origin: "California, USA",
  //     materials: ["N/A"],
  //     image1: "https://i.pinimg.com/564x/91/09/d7/9109d769d4033431378a981d1629a0c2.jpg",
  //     ownerName: "Sarah Johnson",
  //     ownerPhoto: "https://images.unsplash.com/photo-1524290266577-e90173d9072a?q=80&w=1526&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //     industry: "Agriculture",
  //     quantity: 1

  //   },
  //   5: {
  //       id: 5,
  //       name: "Handcrafted Wooden Table",
  //       price: 300,
  //       seller: "Woodworking Creations",
  //       description: "Beautiful handcrafted wooden table made from reclaimed wood.",
  //       category: "Handmade",
  //       type: "Table",
  //       variation: "Wooden",
  //       origin: "Local Workshop",
  //       materials: ["Wood"],
  //       image1: "https://i.pinimg.com/564x/ef/0e/df/ef0edfdb47cf86f9990040785febb1ce.jpg",
  //       ownerName: "David Miller",
  //       ownerPhoto: "https://i.pinimg.com/564x/d1/16/2c/d1162c20b7a6a27e12e7f87ebfb16c3a.jpg",
  //       industry: "Handmade",
  //       quantity: 1
  //   },
  //     6: {
  //       id: 6,
  //       name: "Vintage Bicycle",
  //       price: 500,
  //       seller: "Retro Bikes",
  //       description: "Classic vintage bicycle restored to its former glory.",
  //       category: "Sports & Outdoors",
  //       type: "Bicycle",
  //       variation: "Vintage",
  //       origin: "Unknown",
  //       materials: ["Metal"],
  //       image1: "https://i.pinimg.com/564x/1b/a3/05/1ba30586d2326840ba83bcb5566e25bc.jpg",
  //       ownerName: "Emily Brown",
  //       ownerPhoto: "https://images.unsplash.com/photo-1586425225143-0c8f59053610?q=80&w=1559&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",  
  //       industry: "Fashion",
  //       quantity: 1
  //    },
  //     7: {
  //       id: 7,
  //       name: "Organic Honey",
  //       price: 12,
  //       seller: "Local Bee Farm",
  //       description: "Pure, organic honey harvested from local bee farms.",
  //       category: "Food & Beverage",
  //       type: "Honey",
  //       variation: "Raw",
  //       origin: "Local Farm",
  //       materials: ["N/A"],
  //       image1: "https://i.pinimg.com/564x/09/99/6f/09996f90e2ce52c7b7e31850c8364bb7.jpg",
  //       ownerName: "Mark Thompson",
  //       ownerPhoto: "https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       industry: "Agriculture",
  //       quantity: 1

  //     },
  //     8: {
  //       id: 8,
  //       name: "Vintage Pocket Watch",
  //       price: 150,
  //       seller: "Antique Treasures",
  //       description: "Exquisite vintage pocket watch, a timeless piece of history.",
  //       category: "Antiques",
  //       type: "Pocket Watch",
  //       variation: "Vintage",
  //       origin: "Unknown",
  //       materials: ["Metal"],
  //       image1: "https://i.pinimg.com/564x/c0/97/3e/c0973e487ff621b5c47628a885259b66.jpg",
  //       ownerName: "Sophia Wilson",
  //       ownerPhoto: "https://images.unsplash.com/photo-1579591919791-0e3737ae3808?q=80&w=1430&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       industry: "Fashion",
  //       quantity: 1
  //    },
  //     9: {
  //       id: 9,
  //       name: "Organic Apples",
  //       price: 5,
  //       seller: "Organic Market",
  //       description: "Fresh, organic apples sourced directly from local farms.",
  //       category: "Produce",
  //       type: "Apple",
  //       variation: "Organic Gala",
  //       origin: "California, USA",
  //       materials: ["N/A"],
  //       image1: "https://i.pinimg.com/564x/00/ca/b0/00cab08fb5d8d94ecd47d38f6f94c0c9.jpg",
  //       ownerName: "Ramesh Singh",
  //       ownerPhoto: "https://images.unsplash.com/photo-1600180758890-6b94519a8ba6?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       industry: "Agriculture",
  //       quantity: 1

  //     },
  //     10: {
  //       id: 10,
  //       name: "Handmade Soy Candle",
  //       price: 20,
  //       seller: "Artisan Crafts",
  //       description: "Hand-poured soy candle with a delightful fragrance.",
  //       category: "Handmade",
  //       type: "Soy Candle",
  //       variation: "Various Scents",
  //       origin: "Artisan Workshop",
  //       materials: ["Soy Wax"],
  //       image1: "https://i.pinimg.com/564x/87/78/60/87786060049aa20f60442f7e82fbd331.jpg",
  //       ownerName: "Ananya Patel",
  //       ownerPhoto: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D",
  //       industry: "Handmade",
  //       quantity: 1
  //   },
  //     11: {
  //       id: 11,
  //       name: "Organic Tomato",
  //       price: 2.5,
  //       seller: "Farm Fresh Produce",
  //       description: "Fresh, organic tomatoes grown with care.",
  //       category: "Produce",
  //       type: "Tomato",
  //       variation: "Organic Beefsteak",
  //       origin: "Local Farm",
  //       materials: ["N/A"],
  //       image1: "https://i.pinimg.com/564x/cc/2c/c7/cc2cc711558fe39d8837aaa76b9c060f.jpg",
  //       ownerName: "Ramesh Singh",
  //       ownerPhoto: "https://images.unsplash.com/photo-1600180758890-6b94519a8ba6?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       industry: "Agriculture",
  //       quantity: 1

  //     },
  //     12: {
  //       id: 12,
  //       name: "Handmade Leather Wallet",
  //       price: 50,
  //       seller: "Craftsman Leatherworks",
  //       description: "Handcrafted leather wallet, perfect for storing your cards and cash.",
  //       category: "Handmade",
  //       type: "Wallet",
  //       variation: "Leather",
  //       origin: "Artisan Workshop",
  //       materials: ["Leather"],
  //       image1: "https://i.pinimg.com/564x/45/54/a0/4554a071f0da33296fbd8576db40f8a2.jpg",
  //       ownerName: "David Miller",
  //       ownerPhoto: "https://i.pinimg.com/564x/d1/16/2c/d1162c20b7a6a27e12e7f87ebfb16c3a.jpg",
  //       industry: "Handmade",
  
  // quantity: 1
  //   },
  //     13: {
  //       id: 13,
  //       name: "Organic Turmeric Powder",
  //       price: 8,
  //       seller: "Spice Emporium",
  //       description: "Pure organic turmeric powder, known for its health benefits and vibrant color.",
  //       category: "Spices",
  //       type: "Turmeric",
  //       variation: "Powder",
  //       origin: "India",
  //       materials: ["Turmeric"],
  //       image1: "https://i.pinimg.com/564x/63/55/a2/6355a2bfc292467a3907ead3fc77c4c8.jpg",
  //       ownerName: "Rajesh Patel",
  //       ownerPhoto: "https://i.pinimg.com/564x/b1/d6/60/b1d6604155181ed0dad82b885c87fd00.jpg",
  //       industry: "Agriculture",
  //       quantity: 1

  //     },
  //     14: {
  //       id: 14,
  //       name: "Handwoven Silk Sari",
  //       price: 150,
  //       seller: "Silk Paradise",
  //       description: "Exquisite handwoven silk sari, a symbol of elegance and tradition.",
  //       category: "Fashion",
  //       type: "Sari",
  //       variation: "Silk",
  //       origin: "India",
  //       materials: ["Silk"],
  //       image1: "https://i.pinimg.com/564x/f1/d7/83/f1d7834e6a56b261c2d1f83ab3e25b23.jpg",
  //       ownerName: "Priya Sharma",
  //       ownerPhoto: "https://i.pinimg.com/564x/60/4e/be/604ebe1085489b444eadb59b66d07ebc.jpg",
  //       industry: "Fashion",
  //       quantity: 1
  //    },
  //     16: {
  //       id: 16,
  //       name: "Handmade Dhurrie Rug",
  //       price: 80,
  //       seller: "Artisan Rugs",
  //       description: "Handwoven dhurrie rug featuring intricate patterns, perfect for adding warmth to any space.",
  //       category: "Handmade",
  //       type: "Rug",
  //       variation: "Dhurrie",
  //       origin: "India",
  //       materials: ["Cotton", "Wool"],
  //       image1: "https://i.pinimg.com/564x/d4/da/61/d4da6154d35ca362d858b202abddfcf2.jpg",
  //       ownerName: "Ananya Patel",
  //       ownerPhoto: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D",
  //       industry: "Handmade",
  //       quantity: 1
  //   },
  //     17: {
  //       id: 17,
  //       name: "Aromatic Basmati Rice",
  //       price: 20,
  //       seller: "Rice Haven",
  //       description: "Premium quality aromatic basmati rice, known for its long grains and fragrant aroma.",
  //       category: "Grains",
  //       type: "Rice",
  //       variation: "Basmati",
  //       origin: "India",
  //       materials: ["Rice"],
  //       image1: "https://i.pinimg.com/564x/d9/b4/ce/d9b4ced60671467da5d7367d3c1a95eb.jpg",
  //       ownerName: "Ramesh Singh",
  //       ownerPhoto: "https://images.unsplash.com/photo-1600180758890-6b94519a8ba6?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       industry: "Agriculture",
  //       quantity: 1

  //     },
   
  };
  
  export default cartList;
  