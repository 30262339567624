import React from "react";
import { LuLogIn } from "react-icons/lu";
import "./Login.css";

const Login = () => {
  return (
    <div className="login-section">
      <button type="button" className="login-button">
        <span className="login-icon">
          <LuLogIn size={20} />
        </span>
        <span className="login-text">Login/Signup</span>
      </button>
    </div>
  );
};

export default Login;

/*import React, { useState } from "react";
import "./Login.css";
import { LuLogIn } from "react-icons/lu";
import Signup from "../Map/Signup";
//import Buyer from "./Buyer";

const Login = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
  };

  return (
    <div>
      {!isClicked ? (
        <div className="login-div" onClick={handleClick}>
          {/* Your login/signup content */
/*<LuLogIn
            size={20}
            style={{ marginRight: "15px", marginLeft: "10px" }}
          />
          <div style={{ marginRight: "10px" }} className="login-content">
            Login/Signup
          </div>
        </div>
      ) : (
        <Signup />
      )}
    </div>
  );
};
export default Login;*/
