import React, { useEffect, useState } from 'react';
import './index.css';
import SimilarProduct from './SimilarProduct';
import { RxCross2 } from "react-icons/rx";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

function ProductDetail({ handleProducDetail }) {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  

  const fetchData = async () => {
    try {
      const response = await axios.get(`/getproduct/${productId}`);
      const productData = response.data;
      console.log('Product Details:', productData);
      setProduct(productData);
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };

  const goBack = (e) =>{
      e.preventDefault();
      navigate(-1);
  }

  if (!product) {
    return <div>Loading...</div>;
  }
  const handleSecondaryImageClick = (index) => {
  
    if (index !== mainImageIndex) {
      setMainImageIndex(index);
    }
  };

 

  return (
    <div className='product_detail_container'>
      <div className='container'>
        <div className='content'>
          <div className='top'>
            <div className='product_images'>
            <img
                src={`https://back.tryba.online/uploads/${product.images[0][mainImageIndex]}`}
                alt={`Main Img ${mainImageIndex + 1}`}
                className="main-image"
              />
              <div className="secondary-images">
                {product.images[0].length > 1 && ( // Ensure there are secondary images
                  product.images[0].map((imagePath, index) => (
                    index !== mainImageIndex && (
                      <img
                        key={index}
                        src={`https://back.tryba.online/uploads/${imagePath}`}
                        alt={`Img ${index + 1}`}
                        onClick={() => handleSecondaryImageClick(index)}
                      />
                    )
                  ))
                )}
              </div>

            </div>
            <div className='product_info'>
              <h5>{product.name}</h5>
              <span>
                <h2>{product.price}/-</h2>
                <p>from {product.seller}</p>
              </span>
              <p>{product.description}</p>
            </div>
          </div>
          <button>
            DETAILS
          </button>
          <div className='more_info'>
            <table>
              <tbody>
                <tr>
                  <td><span>Category</span></td>
                  <td className='text'>{product.category}</td>
                </tr>
                <tr>
                  <td><span>Type</span></td>
                  <td className='text'>{product.type}</td>
                </tr>
                <tr>
                  <td><span>Variation</span></td>
                  <td className='text'>{product.variation}</td>
                </tr>
                <tr>
                  <td><span>Origin</span></td>
                  <td className='text'>{product.origin}</td>
                </tr>
                <tr>
                  <td><span>Materials</span></td>
                  <td className='text'>{product.material}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4>Similar Products</h4>
          <div className='similar_products'>
            {/* Render similar products */}
          </div>
        </div>
        <div className='cancel_button' onClick={handleProducDetail}>
          <RxCross2 scale={30} onClick={goBack} />
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
