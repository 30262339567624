import React, { Fragment, useEffect, useState } from 'react';
import options from './options';
import './index.css'
import { FaChevronRight } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";



function FilterDropdown() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleItemClick = (itemId) => {
    if (openDropdown === itemId) {
  
      setOpenDropdown(null);
    } else {
      
      setOpenDropdown(itemId);
    }

    console.log("Selected items:", selectedItems);
  };

  const handleSubItemClick = (subItem) => {
    if (selectedItems.includes(subItem)) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== subItem));
    } else {
      setSelectedItems([...selectedItems, subItem]);
    }

    console.log("Selected items:", selectedItems);
  };

  useEffect(() => {
    console.log("Selected items:", selectedItems);
  }, [selectedItems]);

  const renderSubItems = (subItems) => {
    return (
      <ul className='subItem'>
        {subItems.map((subItem) => (
          <li key={subItem.id} onClick={() => handleSubItemClick(subItem)} className={selectedItems.includes(subItem) ? 'selected' : ''}>
          
          <div className="subItem_name">
        {selectedItems.includes(subItem) && <span />}
        {subItem.name}
       </div>

             {selectedItems.includes(subItem) && <FaCheck />}
          </li>
        ))}
      </ul>
    );
  };

  const renderItems = (items) => {
    return (
      <ul>
        {items.map((item) => (
          <li key={item.id}>
            <div className={`filter_option ${openDropdown === item.id ? 'open' : ''}`} onClick={() => handleItemClick(item.id)}>
              {item.name}
              {item.subItems && <FaChevronRight size={8}/>}
            </div>
            {openDropdown === item.id && item.subItems && renderSubItems(item.subItems)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className='filter_container'>
      {renderItems(options)}
      <div>
    
      </div>
    </div>
  );
}

export default FilterDropdown;
