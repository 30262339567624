import React, { useRef, useState } from 'react';
import './addproduct.css';
import { RiExpandUpDownLine } from "react-icons/ri";
import { LuChevronLeft } from "react-icons/lu";
import file from './icons/backup.svg';
import { IoCheckmark, IoClose } from "react-icons/io5";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import location from './icons/location.svg'


const customMarkerIcon = new L.Icon({
  iconUrl: location,
  iconSize: [25, 41],
});

function AddProduct() {
  const fileInputRef = useRef(null);
  const [showCategoryOptions, setShowCategoryOptions] = useState(false);
  const [selectedCategoryOption, setSelectedCategoryOption] = useState('');
  const [showTypeOptions, setShowTypeOptions] = useState(false);
  const [selectedTypeOption, setSelectedTypeOption] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    inventory: '',
    volume: '',
    variation: '',
    origin: '',
    material: '',
    description: '',
    tags: ''
  });

  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1);
  }

  const toggleCategoryOptions = () => {
    setShowCategoryOptions(!showCategoryOptions);
    setShowTypeOptions(false);
  };

  const toggleTypeOptions = () => {
    setShowTypeOptions(!showTypeOptions);
    setShowCategoryOptions(false);
  };

  const handleCategoryOptionClick = (option) => {
    setSelectedCategoryOption(option);
    setShowCategoryOptions(false);
  };

  const handleTypeOptionClick = (option) => {
    setSelectedTypeOption(option);
    setShowTypeOptions(false);
  };

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
  const files = Array.from(event.target.files).slice(0, 5);
  setSelectedImages(files);
};

  const handleRemoveImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleChange = (e) => {
  const { id, value } = e.target;
  setFormData({
    ...formData,
    [id]: value
  });
};

const MapPicker = ({ setMarkerPosition, setFormData  }) => {

  const map = useMapEvents({
    click(e) {
      const { lat, lng } = e.latlng;
      setMarkerPosition({ lat, lng });
      setFormData({
        ...formData,
        origin: `${lat}, ${lng}`
      });
    },
  });

  return markerPosition === null ? null : (
    <Marker position={markerPosition} icon={customMarkerIcon}>
      <Popup><img src={location} alt="Location Icon" /></Popup>
    </Marker>
  );
};

const handleSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true);
  
  if (
    !formData.name ||
    !formData.price ||
    !formData.inventory ||
    !formData.volume ||
    !selectedCategoryOption ||
    !selectedTypeOption ||
    selectedImages.length === 0 ||
    !markerPosition
  ) {
    console.error("Please fill out all required fields and upload at least one image");
    setIsLoading(false);
    return;
  }

  console.log("Selected Images:", selectedImages);
  const formDataToSend = new FormData();
  formDataToSend.append('name', formData.name);
  formDataToSend.append('price', formData.price);
  formDataToSend.append('inventory', formData.inventory);
  formDataToSend.append('volume', formData.volume);
  formDataToSend.append('variation', formData.variation);
  formDataToSend.append('origin', `${markerPosition.lat}, ${markerPosition.lng}`);
  formDataToSend.append('material', formData.material);
  formDataToSend.append('description', formData.description);
  formDataToSend.append('tags', formData.tags);
  formDataToSend.append('category', selectedCategoryOption);
  formDataToSend.append('type', selectedTypeOption);
  
  selectedImages.forEach((image) => {
        formDataToSend.append("images", image);
      });

  try {
    const response = await axios.post("/addproduct", formDataToSend, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(response.data);
    setIsLoading(false);
  } catch (error) {
    console.error("Error:", error);
    setIsLoading(false);
  }
};

const setLocation = (latlng) => {
  setFormData({
    ...formData,
    origin: `${latlng.lat}, ${latlng.lng}`
  });
};

  const categoryOptions = [
    'Agriculture',
    'Textile',
    'Food',
    'Pharma'
  ];
  
  const typeOptions = [
    'Option 1',
    'Option 2',
    'Option 3',
    'Option 4'
  ];


  return (
    <div className='product-container'>
      <div className='head_bar'>
        <button className='blue-btn' onClick={goBack}><LuChevronLeft/> Back</button>
      </div>

      <div className='product_body'>
        <div className='main-container'>
          <span className='page_label'>
            <h4>Add new GI Product</h4>
          </span>
          <hr/>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className='form-field'>
              <label htmlFor='name'>Name</label>
              <input type='text' id='name' name='name' value={formData.name} onChange={handleChange} required />
            </div>

            <div className='form-field'>
              <label htmlFor='images'>Images</label>
              <span onClick={handleFileInputClick} className='file-input'>
                <input
                  type='file'
                  id='images'
                  accept='image/*'
                  name='images'
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileInputChange}
                  multiple
                />
                <p>Upload upto 5 images</p>
                <img src={file} alt='upld'/>
              </span>
              <div className='selected-image_holder'>
                {selectedImages.map((image, index) => (
                  <div key={index} className="selected-image">
                    <span>{image.name}</span>
                    <IoClose className="remove-icon" onClick={() => handleRemoveImage(index)} />
                  </div>
                ))}
              </div>
            </div>

            <div className='form-field'>
              <label htmlFor='category'>Category</label>
              <div className='custom-dropdown'>
                <div className='dropdown-header' onClick={toggleCategoryOptions}>
                  <input
                    type='text'
                    id='category'
                    value={selectedCategoryOption || ''}
                    placeholder='Select category...'
                    readOnly
                  />
                  <RiExpandUpDownLine/>
                </div>
                {showCategoryOptions && (
                  <div className='dropdown-options'>
                    {categoryOptions.map(option => (
                      <p key={option} onClick={() => handleCategoryOptionClick(option)}>
                        <span>{option}</span>
                        {selectedCategoryOption === option && <span><IoCheckmark size={16} /></span>}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className='form-field'>
              <label htmlFor='type'>Type</label>
              <div className='custom-dropdown'>
                <div className='dropdown-header' onClick={toggleTypeOptions}>
                  <input
                    type='text'
                    id='type'
                    value={selectedTypeOption || ''}
                    placeholder='Select type...'
                    readOnly
                  />
                  <RiExpandUpDownLine/>
                </div>
                {showTypeOptions && (
                  <div className='dropdown-options'>
                    {typeOptions.map(option => (
                      <p key={option} onClick={() => handleTypeOptionClick(option)}>
                        <span>{option}</span>
                        {selectedTypeOption === option && <span><IoCheckmark size={16} /></span>}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className='form-field'>
              <label htmlFor='price'>Price</label>
              <input type='number' id='price' name='price' value={formData.price} onChange={handleChange} placeholder='₹' required/>
            </div>

            <div className='form-field'>
              <label htmlFor='inventory'>Inventory</label>
              <input type='number' id='inventory' name='inventory' value={formData.inventory} onChange={handleChange} required />
            </div>

            <div className='form-field'>
              <label htmlFor='volume'>Volume</label>
              <input type='number' id='volume' name='volume' value={formData.volume} onChange={handleChange} required />
            </div>

            <div className='form-field'>
              <label htmlFor='variation'>Variation</label>
              <input type='text' id='variation' name='variation' value={formData.variation} onChange={handleChange} required />
            </div>

            <div className='form-field'>
              <label htmlFor='origin'>Origin Location</label>
              <input type='text' id='origin' placeholder='Select from map' name='origin' value={formData.origin} onChange={handleChange} required />
            </div>

            <div className='form-field'>
              <label htmlFor='map'>Select Location</label>
              <MapContainer center={[13.002, 77.5907]} zoom={13} scrollWheelZoom={false} style={{ height: "300px", width: "100%" }}>
                <TileLayer url="https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZGVjb3JzbmFwIiwiYSI6ImNrcTVjNmRwdzByM20ybnBwenVpbTBwcG8ifQ.-CUcCijKzxvvfry7qqxdzQ"
                 attribution='© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>' />
                <MapPicker setMarkerPosition={setMarkerPosition} setFormData={setFormData} />
              </MapContainer>
            </div>

            <div className='form-field'>
              <label htmlFor='material'>Material used</label>
              <input type='text' id='material' name='material' value={formData.material} onChange={handleChange} required />
            </div>

            <div className='form-field'>
              <label htmlFor='description'>Description</label>
              <textarea id='description' value={formData.description} name='description' onChange={handleChange} required />
              <p className='max-char'>Max. 2000 Characters</p>
            </div>

            <div className='form-field'>
              <label htmlFor='tags'><span>SEO Keywords</span> <p>use (.) to sep. tags</p></label>
              <input type='text' id='tags' name='tags' value={formData.tags} onChange={handleChange} required />
            </div>

            <div className='btns'>
              <button className='cancel'>Cancel</button>
              <button type="submit" className={`blue-btn ${isLoading ? 'disabled-btn' : ''}`} onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Adding...' : 'Add Product'}
                </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
