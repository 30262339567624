import React from 'react'
import './confirmation.css'
import orderCompleted from './order_complete.svg'

function Confirmation() {
  return (
    <div className='confirmation'>
      <div className='conf-holder'>
        <img src={orderCompleted} alt='confirmation-img'/>
      <h4><b>Hooray! Your order is confirmed! 🎉</b></h4>
      <button>Continue Shopping</button>
      </div>
      
    </div>
  )
}

export default Confirmation