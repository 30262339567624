import React from 'react'
import './cartsummary.css'

function CartSummary({cartItems}) {
  return (
    <div>
        <div className='cart_summary'>
                  {cartItems.map(item => (
                    <div key={item.id} className='summary_item'>
                        <span className='left_holder'>
                      <img src={item.image1} alt={item.name} />
                      <div className='item_details'>
                        <p>{item.name}</p>
                        <p className='variation'>{item.variation}</p>
                      </div>
                      </span>
                      <p className='price'>Rs.{item.price * item.quantity}</p>
                    </div>
                  ))}
                </div>
    </div>
  )
}

export default CartSummary