import React, { useState } from 'react';
import './deliveryInfo.css';

function DeliveryInfo({nextStep}) {
  const [selectedAddress, setSelectedAddress] = useState('');
  const [lineAddress, setLineAddress] = useState('');
  const [areaAddress, setAreaAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPincode] = useState('');
  const [country, setCountry] = useState('');
  const [deliveryOption, setDeliveryOption] = useState('standard');
  const [saveAddress, setSaveAddress] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const savedAddresses = [
    "123 Main St, City, Country",
    "456 Elm St, Town, Country",
    "789 Oak St, Village, Country",
    // Add more addresses as needed
  ];

  const handleAddressChange = (event) => {
    setSelectedAddress(event.target.value);
  };

  const handleLineAddressChange = (event) => {
    setLineAddress(event.target.value);
  };

  const handleAreaAddressChange = (event) => {
    setAreaAddress(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handlePincodeChange = (event) => {
    setPincode(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleDeliveryOptionChange = (event) => {
    setDeliveryOption(event.target.value);
  };

  const handleSaveAddressChange = (event) => {
    setSaveAddress(event.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    if (validateForm()) {
      // Proceed with form submission
      nextStep();
    } else {
      console.log("Form is not valid. Please fill in all fields.");
    }
  };

  const validateForm = () => {
    return (
     
      lineAddress.trim() !== '' &&
      areaAddress.trim() !== '' &&
      city.trim() !== '' &&
      state.trim() !== '' &&
      pincode.trim() !== '' &&
      country.trim() !== ''
    );
  };

  return (
    <div className='delivery_container'>
      <h1>Select Shipping Address</h1>
      <form onSubmit={handleSubmit}>
        <div className='split_section'>
          <p>Use saved address</p>
          <span>
            <select value={selectedAddress} onChange={handleAddressChange} className="custom-select">
              <option value="">Select an address</option>
              {/* Map through pre-saved addresses and create options */}
              {savedAddresses.map((address, index) => (
                <option key={index} value={address}>{address}</option>
              ))}
            </select>
          </span>
        </div>
        <label htmlFor='line_address'>Enter first line of address</label>
        <input type='text' id='line_address' value={lineAddress} onChange={handleLineAddressChange} className={(lineAddress.trim() === '' && formSubmitted) ? 'red-border' : ''} />
        <label htmlFor='area_address'>Enter street/area name</label>
        <input type='text' id='area_address' value={areaAddress} onChange={handleAreaAddressChange} className={(areaAddress.trim() === '' && formSubmitted) ? 'red-border' : ''} />

        <div className='split_section'>
          <div className='split_left'>
            <label htmlFor='city'>Enter city name</label>
            <input type='text' id='city' value={city} onChange={handleCityChange} className={(city.trim() === '' && formSubmitted) ? 'red-border' : ''} />

            <label htmlFor='state'>Enter state name</label>
            <input type='text' id='state' value={state} onChange={handleStateChange} className={(state.trim() === '' && formSubmitted) ? 'red-border' : ''} />
          </div>
          <div className='split_right'>
            <label htmlFor='pincode'>Enter PIN code</label>
            <input type='text' id='pincode' value={pincode} onChange={handlePincodeChange} className={(pincode.trim() === '' && formSubmitted) ? 'red-border' : ''} />

            <label htmlFor='country'>Enter state country</label>
            <input type='text' id='country' value={country} onChange={handleCountryChange} className={(country.trim() === '' && formSubmitted) ? 'red-border' : ''} />
          </div>
        </div>
        <label htmlFor='delivery_options'>Select Shipping</label>
        <select className="custom-select shipping" id='delivery_options' value={deliveryOption} onChange={handleDeliveryOptionChange}>
          <option value="standard">Standard Delivery</option>
          <option value="express">Express Delivery</option>
          <option value="next-day">Next Day Delivery</option>
        </select>

        <div className="save_address_checkbox">
          <input type="checkbox" id="save_address" checked={saveAddress} onChange={handleSaveAddressChange} />
          <label htmlFor="save_address" className='check_save'><span>Save this address for future</span></label>
        </div>

        <div className='remaining-fields'>
          {!validateForm() && formSubmitted && <span className="red-text">Please fill in all fields.</span>}
        </div>

        <div className='btn'>
          <button type="submit" >Enter card details</button>
        </div>
      </form>
    </div>
  );
}

export default DeliveryInfo;
