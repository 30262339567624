import React, { useState } from "react";
import "./Signin.css";
import sign1 from "../icons/sign1.png";
import sign2 from "../icons/sign2.png";
import sign3 from "../icons/sign3.png";
import { Link, useNavigate } from "react-router-dom";

import GoogleForm from "./GoogleForm";
import axios from "axios";
import { useAuth } from "../../context/userContext";

const Signin = () => {
  const { setUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notification, setNotification] = useState("");

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleSignin = async (event) => {
    event.preventDefault();

    if (!email || !password) {
      setNotification("Please fill in all required fields.");
      return;
    }
    // Navigate to the signin route

    if (isUserLoggedIn) {
      navigate("/dashboard/AddProduct");
    } else {
      navigate("/");
    }
  };

  const handleDotClick = (dotNumber) => {
    let imageSource;
    const dots = document.querySelectorAll(".dot");
    dots.forEach((dot, index) => {
      if (index === dotNumber - 1) {
        dot.classList.add("expanded");
      } else {
        dot.classList.remove("expanded");
      }
    });

    // Set image source based on dot clicked
    switch (dotNumber) {
      case 1:
        imageSource = sign1;
        document.querySelector(".signup-footer h3").innerText =
          "Map-Driven Product Discovery";
        break;
      case 2:
        imageSource = sign2;
        document.querySelector(".signup-footer h3").innerText =
          "Verified Sellers & Legal Compliance";
        break;
      case 3:
        imageSource = sign3;
        document.querySelector(".signup-footer h3").innerText =
          "Visual Supply Chain Transparency & Personalized Shopping";
        break;
      default:
        break;
    }

    // Update image source
    document.getElementById("imageElement").src = imageSource;
  };

  // const handleSignin = async (event) => {
  //   event.preventDefault();

  //   try {
  //     // Send login request to backend
  //     const response = await axios.post("/api/v1/auth/login", {
  //       email,
  //       password,
  //     });

  //     // Check if login was successful
  //     if (response.status === 200) {
  //       // Redirect to dashboard or any other page upon successful login
  //       navigate("/");
  //     } else {
  //       setNotification("Login failed. Please check your credentials.");
  //     }
  //   } catch (error) {
  //     console.error("Error logging in:", error);
  //     setNotification("An error occurred while logging in. Please try again.");
  //   }
  // };

  return (
    <>
      <div className="outer-signinpage" onClick={() => navigate("/")}></div>
      <div className="signin-page-container">
        <div className="signin-page-section">
          <div className="signin-section">
            <div>
              {/* <img src={sign1} alt="signup" className="signup-image" /> */}
              <img
                id="imageElement"
                src={sign1}
                alt="signup"
                className="signup-image"
              />
            </div>
            <div className="signup-footer">
              <h3>Map-Driven Product Discovery</h3>
            </div>

            <div className="signin-dots-container">
              <span className="dot" onClick={() => handleDotClick(1)}></span>
              <span className="dot" onClick={() => handleDotClick(2)}></span>
              <span className="dot" onClick={() => handleDotClick(3)}></span>
            </div>
          </div>
          <div className="signin-form-section">
            <div className="signin-form-title">
              <h4>GFOKK</h4>
            </div>
            <div className="welcome-back">
              <h2>Welcome Back</h2>
            </div>
            <p className="signup-form-para">
              Not registered yet?<Link to="/signup">Sign Up</Link>
            </p>
            <form onSubmit={handleSignin}>
              <div className="signin-form-label">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  // value={formData.email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Password"
                  // value={formData.password}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {notification && (
                <div className="signin-notification">{notification}</div>
              )}

              <div className="signup-button">
                <button type="submit">Sign In</button>

                <p className="separator">OR</p>
                <GoogleForm />

                <p className="signin-para">
                  By Signing up, I agree to the
                  <span className="underline-text">Terms of Service</span> and
                  <span className="underline-text">Privacy Policy</span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

{
  /* 
            <div className="signup-button">
              <button type="submit">Sign In</button>
              <p className="separator">OR</p>
              <GoogleForm />
            </div>

            <p className="signin-para">
              By Signing up, I agree to the
              <span className="underline-text">Terms of Service</span> and
              <span className="underline-text">Privacy Policy</span>
            </p>
          
        </div>
        
      </div>
      
    </div> */
}

export default Signin;

// import React, { useState } from "react";
// import "./Signin.css";
// import signup1 from "../icons/signup1.png";
// import { Link, useNavigate } from "react-router-dom";
// import GoogleForm from "./GoogleForm";

// const Signin = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [notification, setNotification] = useState("");
//   const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
//   const navigate = useNavigate();

//   const handleSignin = (event) => {
//     event.preventDefault();
//     if (isUserLoggedIn) {
//       navigate("/dashboard/AddProduct");
//     } else {
//       navigate("/");
//     }

//     // Navigate to the home page
//     // navigate("/");
//     // navigate("/dashboard/AddProduct");
//   };

//   return (
//     <div className="signin-page-container">
//       <div className="signin-page-section">
//         <div className="signin-section">
//           <img src={signup1} alt="signup" className="signin-image" />
//         </div>
//         <div className="signin-form-section">
//           <div className="signin-form-title">
//             <h4>GFOKK</h4>
//           </div>
//           <div className="welcome-back">
//             <h2>Welcome Back</h2>
//           </div>
//           <p className="signin-form-para">
//             Not registered yet?<a href="/signup">Sign Up</a>
//           </p>
//           <form onSubmit={handleSignin}>
//             <div className="signin-form-label">
//               <input
//                 type="email"
//                 placeholder="Email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//               <input
//                 type="password"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//             </div>
//             {notification && (
//               <div className="signin-notification">{notification}</div>
//             )}
//             <div className="signup-button">
//               <button type="submit">Sign In</button>
//               <p className="separator">OR</p>
//               <GoogleForm />
//             </div>
//           </form>
//           <p className="signin-para">
//             By Signing up, I agree to the
//             <span className="underline-text">Terms of Service</span> and
//             <span className="underline-text">Privacy Policy</span>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Signin;
