import React, { useEffect, useRef, useState } from 'react'
import './editproduct.css'
import { RiExpandUpDownLine } from "react-icons/ri";
import { LuChevronLeft } from "react-icons/lu";
import file from './icons/backup.svg';
import { IoCheckmark, IoClose } from "react-icons/io5";
import axios from 'axios';
import { Form, useNavigate, useParams } from 'react-router-dom';

function EditProduct() {
  const { productId } = useParams();
  const fileInputRef = useRef(null);
  const [showCategoryOptions, setShowCategoryOptions] = useState(false);
  const [selectedCategoryOption, setSelectedCategoryOption] = useState('');
  const [showTypeOptions, setShowTypeOptions] = useState(false);
  const [selectedTypeOption, setSelectedTypeOption] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    inventory: '',
    volume: '',
    variation: '',
    origin: '',
    material: '',
    description: '',
    tags: ''
  });

  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/getproduct/${productId}`);
      const productData = response.data;
      console.log('Product Details:', productData);

      const baseUrl = 'http://localhost:5000/uploads';
    

      const images = productData.images.map(image => {
        const parts = image.split('/');
        return parts[parts.length - 1]; // Get the last part of the path, which is the file name
      });
      setSelectedImages(images);

      setFormData({
        name: productData.name || '',
        price: productData.price || '',
        inventory: productData.inventory || '',
        volume: productData.volume || '',
        variation: productData.variation || '',
        origin: productData.origin || '',
        material: productData.material || '',
        description: productData.description || '',
        tags: productData.tags || ''
      });
      setSelectedCategoryOption(productData.category || '');
      setSelectedTypeOption(productData.type || '');
      // Assuming you have a field for images in productData
      
    } catch (error) {
      console.error('Error fetching product data:', error);
      // Handle error fetching data
    }
  };

  const toggleCategoryOptions = () => {
    setShowCategoryOptions(!showCategoryOptions);
    setShowTypeOptions(false);
  };

  const toggleTypeOptions = () => {
    setShowTypeOptions(!showTypeOptions);
    setShowCategoryOptions(false);
  };

  const handleCategoryOptionClick = (option) => {
    setSelectedCategoryOption(option);
    setShowCategoryOptions(false);
  };

  const handleTypeOptionClick = (option) => {
    setSelectedTypeOption(option);
    setShowTypeOptions(false);
  };

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const files = Array.from(event.target.files).slice(0, 5);
    // Extract filenames from the File objects
    const filenames = files.map(file => file.name);
    setSelectedImages(filenames);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

const handleChange = (e) => {
  const { id, value } = e.target;
  setFormData({
    ...formData,
    [id]: value
  });
};

const handleSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true);

  const requestData = new FormData();
  requestData.append('name', formData.name);
requestData.append('price', formData.price);
requestData.append('inventory', formData.inventory);
requestData.append('volume', formData.volume);
requestData.append('variation', formData.variation);
requestData.append('origin', formData.origin);
requestData.append('material', formData.material);
requestData.append('description', formData.description);
requestData.append('tags', formData.tags);
requestData.append('category', selectedCategoryOption);
requestData.append('type', selectedTypeOption);

// Assuming `selectedImages` is an array of File objects
selectedImages.forEach((image, index) => {
  requestData.append(`image_${index}`, image);
});

  try {
    const response = await axios.put(`/updateproduct/${productId}`, requestData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    setFormData(response.data.product);
    console.log(response.data);
    setIsLoading(false);
  } catch (error) {
    console.error('Error updating product:', error);
    setIsLoading(false);
  }
};



  const categoryOptions = [
    'Agriculture',
    'Textile',
    'Food',
    'Pharma'
  ];
  
  const typeOptions = [
    'Option 1',
    'Option 2',
    'Option 3',
    'Option 4'
  ];
  return (
    <div className='product-container'>
    <div className='head_bar'>
      <button className='blue-btn' onClick={goBack}><LuChevronLeft/> Back</button>
    </div>

    <div className='product_body'>
      <div className='main-container'>
        <span className='page_label'>
          <h4>Edit GI Product</h4>
        </span>
        <hr/>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className='form-field'>
            <label htmlFor='name'>Name</label>
            <input type='text' id='name' name='name' value={formData.name} onChange={handleChange} required />
          </div>

          <div className='form-field'>
            <label htmlFor='images'>Images</label>
            <span onClick={handleFileInputClick} className='file-input'>
              <input
                type='file'
                id='images'
                accept='image/*'
                name='images'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileInputChange}
                multiple
              />
              <p>Upload upto 5 images</p>
              <img src={file} alt='upld'/>
            </span>
            <div className='selected-image_holder'>
            {selectedImages.map((imageName, index) => (
              <div key={index} className="selected-image">
                <span>{imageName}</span> {/* Display the filename */}
                <IoClose className="remove-icon" onClick={() => handleRemoveImage(index)} />
              </div>
            ))}
            </div>
          </div>

          <div className='form-field'>
            <label htmlFor='category'>Category</label>
            <div className='custom-dropdown'>
              <div className='dropdown-header' onClick={toggleCategoryOptions}>
                <input
                  type='text'
                  id='category'
                  value={selectedCategoryOption || ''}
                  placeholder='Select category...'
                  readOnly
                />
                <RiExpandUpDownLine/>
              </div>
              {showCategoryOptions && (
                <div className='dropdown-options'>
                  {categoryOptions.map(option => (
                    <p key={option} onClick={() => handleCategoryOptionClick(option)}>
                      <span>{option}</span>
                      {selectedCategoryOption === option && <span><IoCheckmark size={16} /></span>}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className='form-field'>
            <label htmlFor='type'>Type</label>
            <div className='custom-dropdown'>
              <div className='dropdown-header' onClick={toggleTypeOptions}>
                <input
                  type='text'
                  id='type'
                  value={selectedTypeOption || ''}
                  placeholder='Select type...'
                  readOnly
                />
                <RiExpandUpDownLine/>
              </div>
              {showTypeOptions && (
                <div className='dropdown-options'>
                  {typeOptions.map(option => (
                    <p key={option} onClick={() => handleTypeOptionClick(option)}>
                      <span>{option}</span>
                      {selectedTypeOption === option && <span><IoCheckmark size={16} /></span>}
                    </p>
                  ))}
                </div>
              )}

            </div>
          </div>

          <div className='form-field'>
            <label htmlFor='price'>Price</label>
            <input type='number' id='price' name='price' value={formData.price} onChange={handleChange} placeholder='₹' required/>
          </div>

          <div className='form-field'>
            <label htmlFor='inventory'>Inventory</label>
            <input type='number' id='inventory' name='inventory' value={formData.inventory} onChange={handleChange} required />
          </div>

          <div className='form-field'>
            <label htmlFor='volume'>Volume</label>
            <input type='number' id='volume' name='volume' value={formData.volume} onChange={handleChange} required />
          </div>

          <div className='form-field'>
            <label htmlFor='variation'>Variation</label>
            <input type='text' id='variation' name='variation' value={formData.variation} onChange={handleChange} required />
          </div>

          <div className='form-field'>
            <label htmlFor='origin'>Origin Location</label>
            <input type='text' id='origin' name='origin' value={formData.origin} onChange={handleChange} required />
          </div>

          <div className='form-field'>
            <label htmlFor='material'>Material used</label>
            <input type='text' id='material' name='material' value={formData.material} onChange={handleChange} required />
          </div>

          <div className='form-field'>
            <label htmlFor='description'>Description</label>
            <textarea id='description' value={formData.description} name='description' onChange={handleChange} required />
            <p className='max-char'>Max. 2000 Characters</p>
          </div>

          <div className='form-field'>
            <label htmlFor='tags'><span>SEO Keywords</span> <p>use (.) to sep. tags</p></label>
            <input type='text' id='tags' name='tags' value={formData.tags} onChange={handleChange} required />
          </div>

          <div className='btns'>
            <button className='cancel'>Cancel</button>
            <button type="submit" className={`blue-btn ${isLoading ? 'disabled-btn' : ''}`} onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? 'Updating...' : 'Update Product'}
              </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  )
}

export default EditProduct