import React, { useState } from "react";
import MapView from "./Map";
import { FaSearch, FaUser } from "react-icons/fa";
import { MdOutlineFilterAlt } from "react-icons/md";
import { GrCart } from "react-icons/gr";
import userprofile from "../icons/userprofile.png";
//link section
import accountcircle from "../icons/accountcircle.png";
import accountcircle1 from "../icons/accountcircle1.png";
import contactsupport from "../icons/contactsupport.png";
import contactsupport1 from "../icons/contactsupport1.png";
import shield from "../icons/shield.png";
import shield1 from "../icons/shield1.png";
import shopVariant from "../icons/Variant2.png";
import psychiatry from "../icons/psychiatry.png"; //sidesection
import psychiatryColor from "../icons/psychiatryColor.png"; //sidesection hover
import cloths from "../icons/Cloths_inact.svg";
import clothsColor from "../icons/Cloths_active.svg";
import food from "../icons/Food_inact.svg";
import foodColor from "../icons/Food_active.svg";
import pharma from "../icons/Pharma_inact.svg";
import pharmaColor from "../icons/Pharma_active.svg";

//link section after hover
import { AiFillHome, AiOutlineHome } from "react-icons/ai";

import { MdAddToPhotos } from "react-icons/md";

import "./index.css";

import ProductPopup from "../ProductDetail/ProductPopup";
import product from "../ProductDetail/product";
import ProductDetail from "../ProductDetail/ProductDetail";
import FilterDropdown from "../filterdropdown";
import CartComponent from "../Cart/CartComponent";
import cartList from "../Cart/cartList";
import { Link } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { LuLogIn } from "react-icons/lu";
import Searchbar from "./Searchbar";
import { useAuth } from "../../context/userContext";

// import Login from "../Map/Login";

const MapComponent = function (props) {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategorySelection = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="map-wrapper">
      <MapView selectedCategory={selectedCategory} />
      <SearchControl />
      <LinksSection />
      <ShortcutBtnGroup handleCategorySelection={handleCategorySelection} />
    </div>
  );
};
const SearchControl = (props) => {
  const { user } = useAuth()
  const [showLoginPage, setShowLoginPage] = useState(false);
  const [isFilterDropdownVisible, setFilterDropdownVisible] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [cartItems, setCartItems] = useState(Object.values(cartList));
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false); //signin
  const [redirectToAddProduct, setRedirectToAddProduct] = useState(false); //signin
  const handleLogin = () => {
    // Perform login logic here
    // For demonstration, let's assume login is successful
    return true;
  };
  const handleAddProductClick = () => {
    if (!isUserLoggedIn) {
      // If user is not logged in, redirect to sign-in page
      setRedirectToAddProduct(true);
    } else {
      // If user is logged in, navigate to add product page
      // You can replace this with your navigation logic
      navigate("/addproduct");
    }
  };

  const handleSignIn = () => {
    const isLoggedIn = handleLogin(); // Call your login function
    setIsUserLoggedIn(isLoggedIn);
    setRedirectToAddProduct(isLoggedIn); // Redirect to add product if login successful
  };
  const handleUserButtonClick = (e) => {
    // Prevent the default behavior of the click event
    e.preventDefault();
    // Toggle the showLoginPage state
    setShowLoginPage(!showLoginPage);
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownVisible(!isFilterDropdownVisible);
  };

  const toggleShowCart = () => {
    setShowCart(!showCart);
  };

  const totalItems = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const handleAddProduct = () => {
    if (user && user.user && user.user._id) {
   
      navigate('/dashboard/addproduct');
    } else {
  
      navigate('/signin');
    }
  };

  // Apply a different class based on the showLoginPage state
  const userButtonClass = showLoginPage ? "user-button clicked" : "user-button";
  const navigate = useNavigate();
  // const handleAddProduct = () => {
  //   navigate("/signup");
  // };
  const [showMenuItems, setShowMenuItems] = useState(false);
  const handleMenuClick = () => {
    setShowMenuItems(!showMenuItems);
  };
  return (
    <>
      <div className="search-div">
        <div className="search-section">
          <div className="title">GFOKK</div>
          <div className="input-div">
            <Searchbar />
            {/* <div className="search-icon">
              <IoSearchOutline className="svgsearchicon" />
            </div>
            <input
              className="input-search"
              type={"text"}
              placeholder="Search..."
            /> */}
          </div>
          {/* <div className="smallScreen-search-icon">
            <IoSearchOutline className="svgsearchicon" />
          </div> */}
          <div className="filter-btn" onClick={toggleFilterDropdown}>
            <MdOutlineFilterAlt />
          </div>
          {isFilterDropdownVisible && <FilterDropdown />}
        </div>
        <div className="side-items">

          <div className="add_product" onClick={handleAddProduct}>
           

              <MdAddToPhotos fontSize="20px" />
              <p style={{ fontSize: "13px" }}>Add Product</p>
           
          </div>

          {/* <div className="add_product">
            <Link to="/dashboard/addproduct">
              <MdAddToPhotos fontSize="20px" />
              <p style={{ fontSize: "13px" }}>Add Product</p>
            </Link>

            {isUserLoggedIn && redirectToAddProduct && (
            <Link to="/signin">
              <MdAddToPhotos fontSize="20px" />
              <p style={{ fontSize: "13px" }}>Add Product</p>
            </Link>
            )}
          </div> */}
          <div className="cart-button" onClick={toggleShowCart}>
            <GrCart className="cartIcon" size={20} />
            <div className="item_count">{totalItems}</div>
          </div>
          {/*<div className="user-button">
        <FaUser />
      </div>*/}

          <div className={userButtonClass} onClick={handleUserButtonClick}>
            <img
              className="map-userprofile"
              src={userprofile}
              width="50px"
              height="50px"
              alt="userProfile"
            />
          </div>
          {showLoginPage && (
            <div className="login-section">
              <button type="button" className="login-button">
                <Link to="/signup">
                  <span className="login-icon">
                    <LuLogIn size={20} />
                  </span>
                  <span className="login-text">Login/Signup</span>
                </Link>
              </button>
            </div>
          )}
        </div>
      </div>

      {showCart && <CartComponent toggleShowCart={toggleShowCart} />}
    </>
  );
};

const LinksSection = () => {
  const [isClicked, setIsClicked] = useState(false); //this if for home
  const [position, setPosition] = useState(0); //this is for home
  const [walletClicked, setWalletClicked] = useState(false); //this is about us
  const [walletPosition, setWalletPosition] = useState(0); //this is about us
  const [menuClicked, setMenuClicked] = useState(false); //this is for security
  const [menuPosition, setMenuPosition] = useState(0); //this is for security
  const [privacyClicked, setPrivacyClicked] = useState(false); //privacy
  const [privacyPosition, setPrivacyPosition] = useState(0); //privacy
  //this is for home
  const handleClick = () => {
    setIsClicked(!isClicked);
    setPosition(isClicked ? 0 : -10);
    // Reset other images
    setWalletClicked(false);
    setWalletPosition(0);
    setMenuClicked(false);
    setMenuPosition(0);
    setPrivacyClicked(false);
    setPrivacyPosition(0);
  };
  //about us img
  const handleWalletClick = () => {
    setWalletClicked(!walletClicked);
    setWalletPosition(walletClicked ? 0 : -10);
    setIsClicked(false);
    setPosition(0);
    setMenuClicked(false);
    setMenuPosition(0);
    setPrivacyClicked(false);
    setPrivacyPosition(0);
  };
  //this is for security
  const handleMenuClick = () => {
    setMenuClicked(!menuClicked);
    setMenuPosition(menuClicked ? 0 : -10);
    // Reset other images
    setIsClicked(false);
    setPosition(0);
    setWalletClicked(false);
    setWalletPosition(0);
    setPrivacyClicked(false);
    setPrivacyPosition(0);
  };
  //this for privacy
  const handlePrivacyClick = () => {
    setPrivacyClicked(!privacyClicked);
    setPrivacyPosition(privacyPosition ? 0 : -10);
    // Reset other images
    setIsClicked(false);
    setPosition(0);
    setWalletClicked(false);
    setWalletPosition(0);
    setMenuClicked(false);
    setMenuPosition(0);
  };
  return (
    <div className="tos-section">
      <div className="icon-container">
        {isClicked && (
          <hr
            className="hide-line"
            id="hr_1"
            style={{
              width: "40px",
              color: "white",
              position: "absolute",
              top: "-8px",
              marginLeft: "10px",
              borderTop: "1px solid white",
            }}
          ></hr>
        )}
        {isClicked ? (
          <AiFillHome
            size={17}
            className={`tos-link white-icon`}
            id="home"
            style={{
              width: "20px",
              height: "20px",
              padding: "0%",
              position: "relative",
              transition: "top 0.3s ease",
              top: `${position}px`,
              marginLeft: "20px",
              color: "white",
            }}
            onClick={handleClick}
          />
        ) : (
          <AiOutlineHome
            size={17}
            className={`tos-link`}
            id="home_1"
            style={{
              width: "20px",
              height: "20px",
              padding: "0%",
              position: "relative",
              transition: "top 0.3s ease",
              top: `${position}px`,
              marginLeft: "20px",
              //  color: "lightgrey",
              color: "rgba(211, 211, 211, 0.7)",
            }}
            onClick={handleClick}
          />
        )}
        {isClicked && (
          <div
            style={{
              position: "absolute",
              fontSize: "10px",
              marginTop: "25px",
              marginLeft: "16px",
              textAlign: "center",
            }}
            className="hide"
            id="home_text"
          >
            Home
          </div>
        )}
        {walletClicked && (
          <hr
            id="line_aboutus"
            className="hide-line"
            style={{
              width: "40px",
              color: "white",
              position: "absolute",
              top: "-8px",
              marginLeft: "70px",
              borderTop: "1px solid white",
            }}
          ></hr>
        )}
        <img
          src={walletClicked ? accountcircle1 : accountcircle}
          // src={menuwallet}
          //className="tos-link"
          className={`tos-link ${walletClicked ? "white-icon" : ""}`}
          id="myDIV"
          alt="menuwallet"
          style={{
            width: "20px",
            height: "20px",
            position: "relative",
            transition: "top 0.3s ease",
            top: `${walletPosition}px`,
            marginLeft: "-10px",
          }}
          onClick={handleWalletClick}
        />
        {/* <HiOutlineUserCircle className="tos-link" id="myDIV" />*/}
        {walletClicked && (
          <div
            id="text_aboutus"
            className="hide"
            style={{
              position: "absolute",
              fontSize: "10px",
              marginTop: "25px",
              marginLeft: "70px",
              textAlign: "center",
            }}
          >
            About Us
          </div>
        )}
        {/*<MdOutlineContactSupport className="tos-link" id="myDIV" />*/}
        {menuClicked && (
          <hr
            id="line_support"
            className="hide-line"
            style={{
              width: "40px",
              color: "white",
              position: "absolute",
              top: "-8px",
              marginLeft: "134px",
              borderTop: "1px solid white",
            }}
          ></hr>
        )}
        <img
          src={menuClicked ? contactsupport1 : contactsupport}
          //src={menuanalysis}
          //className="tos-link"
          className={`tos-link ${menuClicked ? "white-icon" : ""}`}
          id="myDIV"
          alt="menuanalysis"
          style={{
            width: "20px",
            height: "20px",
            position: "relative",
            transition: "top 0.3s ease",
            top: `${menuPosition}px`,
            marginLeft: "-10px",
          }}
          onClick={handleMenuClick}
        />
        {menuClicked && (
          <div
            style={{
              position: "absolute",
              fontSize: "10px",
              marginTop: "25px",
              marginLeft: "140px",
              textAlign: "center",
            }}
            className="hide"
            id="text_support"
          >
            Support
          </div>
        )}
        {privacyClicked && (
          <hr
            id="line_privacy"
            className="hide-line"
            style={{
              width: "40px",
              color: "white",
              position: "absolute",
              top: "-8px",
              marginLeft: "200px",
              borderTop: "1px solid white",
            }}
          ></hr>
        )}
        <img
          src={privacyClicked ? shield1 : shield}
          //src={menuprofile}
          style={{
            width: "20px",
            height: "20px",
            position: "relative",
            transition: "top 0.3s ease",
            top: `${privacyPosition}px`,
            right: "15px",
          }}
          //src={shield_locked}
          //src={shield}
          alt="shield"
          id="myDIV"
          //className="tos-link"
          className={`tos-link ${privacyClicked ? "white-icon" : ""}`}
          onClick={handlePrivacyClick}
        />
        {privacyClicked && (
          <div
            style={{
              position: "absolute",
              fontSize: "10px",
              marginTop: "25px",
              marginLeft: "205px",
              textAlign: "center",
            }}
            className="hide"
            id="text_privacy"
          >
            Privacy
          </div>
        )}
      </div>
    </div>
  );
};

const ShortcutBtnGroup = (props) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    props.handleCategorySelection(category);
  };

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const toggleShow = () => {
    setIsShown(!isShown);
  };

  return (
    <div
      className={`btn-aside-section ${isHovered ? "hovered" : ""}
      }`}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={toggleShow}
    >
      <div className="btn-group">
        <div
          className="btn-item"
          onClick={() => handleCategoryClick("Agriculture")}
        >
          <img
            src={
              selectedCategory === "Agriculture" ? psychiatryColor : psychiatry
            }
            alt="agriculture"
            width="25px"
            className="short-image"
            id="agriculture"
          />
          <div className="short-hide">Agriculture</div>
        </div>
        <div
          className="btn-item"
          onClick={() => handleCategoryClick("Textile")}
        >
          <img
            src={selectedCategory === "Textile" ? clothsColor : cloths}
            alt="textile"
            width="25px"
            className="short-image"
            id="textile"
          />
          <div className="short-hide">Textiles and Handloom</div>
        </div>
        <div className="btn-item" onClick={() => handleCategoryClick("Food")}>
          <img
            src={selectedCategory === "Food" ? foodColor : food}
            alt="food"
            width="25px"
            className="short-image"
            id="food"
          />
          <div className="short-hide">Food Products</div>
        </div>
        <div className="btn-item" onClick={() => handleCategoryClick("Pharma")}>
          <img
            src={selectedCategory === "Pharma" ? pharmaColor : pharma}
            alt="pharma"
            width="25px"
            className="short-image"
            id="pharma"
          />
          <div className="short-hide">Medicine</div>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
