import React, { useState } from 'react';
import './index.css';
import { Route, Routes } from 'react-router-dom';
import AddProduct from './gi Product/add_product/AddProduct';
import EditProduct from './gi Product/edit_product/EditProduct';
import GiProducts from './gi Product/gi_product/GiProducts';
import ViewProduct from './gi Product/view_product/ViewProduct';
import Sidebar from './sidebar/Sidebar';
import { FiMenu } from 'react-icons/fi';
import { IoIosSearch } from 'react-icons/io';
// import { IoCloseOutline } from "react-icons/io5";

function Index() {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div className={`container ${sidebarOpen ? '' : 'hide-sidebar'}`}>
            <div className='sidebar'>
                <Sidebar />
            </div>

            <div className='dash_body'>
                <div className='head'>
                    <div className='left'>
                        <FiMenu size={25} onClick={toggleSidebar} />
                        <h1>Dashboard</h1>
                    </div>

                    <div className='right'>
                       
                    </div>
                </div>

                <div className='dashboard'>
                    <Routes>
                        <Route path='addproduct' element={<AddProduct />} />
                        <Route path='gi-product' element={<GiProducts />} />

                        {/* Add more routes for other components */}
                        <Route path='editproduct/:productId' element={<EditProduct />} />
                        <Route path='viewproduct/:productId' element={<ViewProduct />} />

                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default Index;
